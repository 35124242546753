import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddressList } from '../../../store/Address';
import { useLocation, useNavigate } from 'react-router-dom';
import ShippingForm from './ShippingForm';
import { instance } from '../../../utils/ApiRequest';
import Loader from '../../Loader';

function ShippingAddress({ checkoutDetails, user }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [changeAddress, setChangeAddress] = useState(false);
    const [error, setError] = useState({
        FullName: '',
        Address1: '',
        Email: '',
        Mobile: '',
        Address2: '',
        City: '',
        State: '',
        Pincode: '',
        CompanyName: ''
    });
    const [formData, setFormData] = useState({
        FullName: '',
        Address1: '',
        Email: '',
        Mobile: '',
        Address2: '',
        City: '',
        State: '',
        Pincode: '',
        CompanyName: ''
    });

    const AllAddress = useSelector(state => state.AddressList.address);

    const { state } = useLocation()

    const DefaultShipping = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await instance.get('account/addresses/default?type=shipping');
            setFormData({
                FullName: res.data.Data.FullName || user.FirstName + user.LastName,
                Address1: res.data.Data.Address1 || '',
                Email: res.data.Data.Email || user.Email,
                Mobile: res.data.Data.Mobile || user.Mobile,
                Address2: res.data.Data.Address2 || '',
                City: res.data.Data.City || '',
                State: res.data.Data.State || '',
                Pincode: res.data.Data.Pincode || '',
                CompanyName: res.data.Data.CompanyName || ''
            });
        } catch (err) {
            
        } finally {
            setIsLoading(false);
        }
    }, [user])

    function SelectAddress(item){
        setFormData({
            FullName: item.FullName,
            Address1: item.Address1,
            Email: item.Email,
            Mobile: item.Mobile,
            Address2: item.Address2,
            City: item.City,
            State: item.State,
            Pincode: item.Pincode,
            CompanyName : item.CompanyName || ''
        });
        setTimeout(() => {
            setChangeAddress(!changeAddress);
        }, 500);
    }

    useEffect(() => {
        if(state == null){
            if(checkoutDetails.PaymentDetail){
                navigate('/checkout/shop/summary');
            }else if(checkoutDetails.BillingAddress){
                navigate('/checkout/shop/payment');
            }else if (checkoutDetails.ShippingAddress) {
                navigate('/checkout/shop/billing');
            }
        }
        if (checkoutDetails.ShippingAddress) {
            setFormData({
                FullName: checkoutDetails.ShippingAddress.FullName || '',
                Address1: checkoutDetails.ShippingAddress.Address1 || '',
                Email: checkoutDetails.ShippingAddress.Email || '',
                Mobile: checkoutDetails.ShippingAddress.Mobile || '',
                Address2: checkoutDetails.ShippingAddress.Address2 || '',
                City: checkoutDetails.ShippingAddress.City || '',
                State: checkoutDetails.ShippingAddress.State || '',
                Pincode: checkoutDetails.ShippingAddress.Pincode || '',
                CompanyName: checkoutDetails.ShippingAddress.CompanyName || ''
            });
            setIsLoading(false);
        } else if (checkoutDetails.UserId !== null) {
            DefaultShipping();
        }
        if (checkoutDetails.UserId !== null) {
            dispatch(fetchAddressList());
        }
    }, [checkoutDetails.BillingAddress, checkoutDetails.ShippingAddress, checkoutDetails.PaymentDetail, checkoutDetails.UserId, dispatch, DefaultShipping, navigate, state]);


    const handleChangeFields = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let hasError = false;
        
        const newError = {};
        for (const key in formData) {
            if (!formData[key] && key !== 'CompanyName' && key !== 'Address2') {
                newError[key] = 'This field is required';
                hasError = true;
            }
            if (key === 'Email' && formData[key] && !formData[key].match(emailRegex)) {
                newError['Email'] = 'Please enter a valid email';
                hasError = true;
            }
        }
        
        if (hasError) {
            setError(newError);
            return;
        }

        try {
            const res = await instance.put('/account/checkout/shop/update-shipping-address', formData);
            if (res.data.Status === "Success") {
                sessionStorage.setItem('formData', JSON.stringify(formData));
                navigate('/checkout/shop/billing', { state: {redirection: "false"} });
            }
        } catch (err) {
            
        }
    }

    return (
        <div className='col-md-12 form-box address mt-3'>
            {checkoutDetails.UserId !== null && AllAddress.Data && AllAddress.Data.length > 0 && (
                <span className="gradient_btn change_address" onClick={() => setChangeAddress(!changeAddress)}>
                    <span>{!changeAddress ? "Change Address" : "Back"}</span>
                </span>
            )}
            {!isLoading ? (
                !changeAddress ? (
                    <div className='address_form'>
                        <ShippingForm
                            onSubmit={onSubmit}
                            error={error}
                            shipping={checkoutDetails.ShippingAddress}
                            updateFields={formData}
                            handleChangeFields={handleChangeFields}
                        />
                    </div>
                ) : (
                    <div className='row address_form'>
                        {AllAddress.Data && AllAddress.Data.map((item, index) => (
                            <div className="col-lg-4" key={index}>
                                <div className="card card-dashboard" onClick={() => SelectAddress(item)}>
                                    <div className="card-body">
                                        <h3 className="card-title">{item.Tag}</h3>
                                        <p>{item.FullName} {item.Address1}, {item.Address2},{item.City}<br />
                                            {item.State} {item.Pincode} {item.Mobile}<br />
                                            {item.Email}<br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            ) : (
                <Loader />
            )}
        </div>
    );
}

export default ShippingAddress;
