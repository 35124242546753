import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AddAddress } from '../../store/Address';
import { instance } from '../../utils/ApiRequest';
import { getStates } from '../../utils';
import Loader from '../Loader';

function UpdateAddressComponent({user}) {
    console.log(user);
    
    const { id }  = useParams();
    const states = getStates();
    const [isEditLoad, setIsEditLoad] = useState(false);
    const [APIError, setAPIError] = useState("");
    const [error, setError] = useState({
        Tag : '',
        FullName:'',
        Address1: '',
        Email: '',
        Mobile: '',
        Address2: '',
        City: '',
        State: '',
        Pincode: '',
    });
    const [formData, setFormData] = useState({
        Tag : '',
        FullName:user.FirstName + " " + user.LastName ||'',
        Address1: '',
        Email: user.Email || '',
        Mobile: user.Mobile || '',
        Address2: '',
        City: '',
        State: '',
        Pincode: '',
        BillingDefault : false,
        ShippingDefault : false
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAddress = useCallback(async() => {
        await instance.get(`/account/addresses/${id}/fetch`)
        .then(res => {
            setFormData({
                Tag: res.data.Data.Tag,
                FullName: res.data.Data.FullName,
                Address1: res.data.Data.Address1,
                Email: res.data.Data.Email,
                Mobile: res.data.Data.Mobile,
                Address2: res.data.Data.Address2,
                City: res.data.Data.City,
                State: res.data.Data.State,
                Pincode: res.data.Data.Pincode,
                BillingDefault : res.data.Data.BillingDefault,
                ShippingDefault : res.data.Data.ShippingDefault
            })
            setIsEditLoad(false);
        })
        .catch(err => {
            
            setIsEditLoad(false);
            alert("Something went wrong");
        })
    }, [id])
    useEffect(() => {
        if(id){
            setIsEditLoad(true);
            getAddress();
        }
    }, [id, getAddress])

    function HandleChangeDefault(event) {
        const {checked, value}  = event.target;
        if(value === "BillingDefault"){
            setFormData({
                ...formData,
                BillingDefault : checked
            })
        }else{
            setFormData({
                ...formData,
                ShippingDefault : checked
            })
        }
    }

    function closeModal () {
        navigate('/addresses');
    }
    const handleChangeFields = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
        ...prevState,
        [name]: value
        }));
    }
    const onSubmit = async(e) => {
        e.preventDefault();
        const email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const newError = {};
        // Validate form data
        for (const key in formData) {
            if (!formData[key] && key !== 'Address2' && key !== 'BillingDefault' && key !== 'ShippingDefault' && key !== 'Tag') {
                newError[key] = 'This field is required';
            }
        }
        // Validate email format
        if (formData.Email && !formData.Email.match(email_regex)) {
        newError.Email = 'Please enter a valid email';
        }
        // Update the error state
        setError(newError);
        
        if(Object.keys(newError).length === 0){
            if(id !== undefined){
                await instance.put(`/account/addresses/${id}/update`, formData)
                .then(res => {
                    setTimeout(() => {
                        navigate('/addresses');
                    }, 400);
                })
                .catch(err => {
                    
                    setAPIError(err.response.data.Message);
                })
            }else{
                dispatch(AddAddress(formData))
                .unwrap()
                .then((res) => {
                    navigate('/addresses');
                })
                .catch(err => {
                    setAPIError(err.response.data.Message)
                });
            }
        }
        setTimeout(() => {
            setError({});
            setAPIError('');
        }, 3000);
    }
  return (
    <div className='address_update address form-box'>
        { id ? <h3>Update Adrress</h3> : <h3>Create Adrress</h3> }
        {!isEditLoad ? 
            <form onSubmit={onSubmit} className='mt-5'>
                <div className="row">
                    <div className="col-sm-6">
                        <label>{id ? "Tag *" : "Tag (optional)"}</label>
                        <input type="text" name="Tag" className="form-control" value={formData.Tag} onChange={handleChangeFields}/>
                        {error.Tag && <span className='text-danger'>{error.Tag}</span>}
                    </div>
                    <div className="col-sm-6">
                        <label>FullName *</label>
                        <input type="text" name="FullName" className="form-control" value={formData.FullName}  onChange={handleChangeFields}/>
                        {error.FullName && <span className='text-danger'>{error.FullName}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Email address *</label>
                        <input type="email" name='Email' className="form-control" value={formData.Email} onChange={handleChangeFields}/>
                        {error.Email && <span className='text-danger'>{error.Email}</span>}
                    </div>
                    <div className="col-sm-6">
                        <label>Mobile *</label>
                        <input type="tel" name='Mobile' className="form-control" value={formData.Mobile} onChange={handleChangeFields}/>
                        {error.Mobile && <span className='text-danger'>{error.Mobile}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Address1 *</label>
                        <input type="text" name="Address1" className="form-control" value={formData.Address1}  onChange={handleChangeFields}/>
                        {error.Address1 && <span className='text-danger'>{error.Address1}</span>}
                    </div>
                    <div className="col-sm-6">
                        <label>Address2 (optional)</label>
                        <input type="text" name="Address2" className="form-control" value={formData.Address2} onChange={handleChangeFields}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 select-custom">
                        <label>State *</label>
                        <select className="form-control form-select-sm"  name='State' id="inputState" defaultValue={formData.State} onChange={handleChangeFields}>
                        {
                            states.map((state, index) => (
                                <option value={state.value} key={index}>{state.label}</option>
                            ))
                        }
                        </select>
                        {error.State && <span className='text-danger'>{error.State}</span>}
                    </div>
                    <div className="col-sm-6">
                        <label>City *</label>
                        <input type="text" name='City' className="form-control" value={formData.City} onChange={handleChangeFields}/>
                        {error.City && <span className='text-danger'>{error.City}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Pincode *</label>
                        <input type="tel" name='Pincode' className="form-control" value={formData.Pincode} onChange={handleChangeFields}/>
                        {error.Pincode && <span className='text-danger'>{error.Pincode}</span>}
                    </div>
                    <div className='col-sm-6 billing_shiping' style={{flexDirection:'column'}}>
                        <div className="col-sm-3">
                            <label>
                                <input type="checkbox" className="main_uni_courses" defaultChecked={formData.BillingDefault === true} value="BillingDefault" id={`default_billing`} onChange={HandleChangeDefault}/>
                                <span htmlFor={`default_billing`}>Billing Default</span>
                            </label>
                        </div>
                        <div className="col-sm-3">
                            <label>
                                <input type="checkbox" className="main_uni_courses" defaultChecked={formData.ShippingDefault === true} value="ShippingDefault" id={`default_shipping`} onChange={HandleChangeDefault}/>
                                <span htmlFor={`default_shipping`}>Shipping Default</span>
                            </label>
                        </div>
                    </div>
                </div>
                {APIError && <span className='text-danger'>{APIError}</span>}
                <div className='action-buttons'>
                    <button type="submit" className="gradient_btn">
                       {!id ? "Add Address" : "Update Address"}
                    </button>
                    <button onClick={closeModal} className="gradient_btn">
                        Cancel
                    </button>
                </div>
            </form>
        : <Loader />}
    </div>
  )
}

export default UpdateAddressComponent;