import axios from "axios";

export const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
})
var GlobalError = null;

// Function to start loader
const startLoader = () => {
  const loaderElement = document.getElementById('loader');
  if (loaderElement) {
    loaderElement.style.display = 'block';
  }
};

// Function to stop loader
const stopLoader = () => {
  const loaderElement = document.getElementById('loader');
  if (loaderElement) {
    loaderElement.style.display = 'none';
  }
};

instance.interceptors.request.use(
  (config) => {
    // need to show loader
    startLoader();
    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401 && window.location.pathname !== '/login') {
      localStorage.removeItem('user_logged_in');
      window.location.href = '/login';
    }
    if(error.response){
      GlobalError = error.response.data.Message;
    }
    stopLoader();
    return Promise.reject(error);
  }
);

// Add a response interceptor to decrement counter and handle errors
instance.interceptors.response.use(
  (response) => {
    // stop the loader
    stopLoader();
    return response;
  },
  (error) => {
  // navigate
    if (error.response && error.response.status === 401 && window.location.pathname !== '/login') {
      localStorage.removeItem('user_logged_in');
      window.location.href = '/login';
    }
    if(error.response){
      GlobalError = error.response.data.Message;
    }
    stopLoader();
    return Promise.reject(error);
  }
);

export {GlobalError};

