import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { DeleteAddress, fetchAddressList} from '../../store/Address';
import useSize from '../Size/useSize';
import { getState } from '../../utils';
import NoResult from '../Features/NoResult';

function Address() {
    const dispatch = useDispatch();
    const width = useSize();
    const AllAddress = useSelector(state => state.AddressList.address);
    
    useEffect(() => {
        dispatch(fetchAddressList());
    },[dispatch])

    const handleDelete = (id) => {
        dispatch(DeleteAddress(id));
        setTimeout( () => {
            dispatch(fetchAddressList());
        }, 400 );
    }

  return (
    <div className='address_list tab-pane'>
        <div className='d-flex justify-content-between align-items-center'>
                { width > 767 && 
                    <div className='address_title'>
                        <span>The following addresses can be used on the checkout page.</span> 
                    </div>
                }
            <Link to="/address/add" className="gradient_btn">
                Add Address
            </Link>
        </div>
        <div className="row mt-3">
            {AllAddress.Status === "Success" ?
             AllAddress.Data.length > 0 ?
                AllAddress.Data && AllAddress.Data.map((item, index) => {
                    const state = getState(item.State);
                    return(   
                    <div className="col-lg-6" key={index}>
                        <div className="card card-dashboard">
                            <div className="card-body">
                                <div className='default-value'>
                                    <h3 className="card-title">{item.Tag}</h3>
                                    {item.BillingDefault === true && <span className='badge bg-info text-dark'>Default Billing </span>}
                                    {item.ShippingDefault === true && <span className='badge bg-warning text-dark'>Default Shipping</span>}
                                </div>
                                <div>{item.FullName} <br />
                                    {item.Email}<br />
                                    {item.Mobile}<br />
                                    {item.Address1}, {item.Address2},{" "}{item.City}<br />
                                    {state ? state.label : item.State},{" "}
                                    {item.Pincode}
                                    <div className='d-flex'>
                                        <Link to={`/address/edit/${item._id}`} >Edit <i className="icon-edit"></i></Link>
                                        <Link href="#" style={{marginLeft: "2rem",color: "red"}} onClick={() => handleDelete(item._id)}>Delete 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )})
            : <NoResult 
                title="No adderesses found" 
                description="Saved addresses will display here."
                btnName = "Add Address"
                btnUrl = "/address/add"
            />:  <div>Loading</div> }
        </div>
    </div>
  )
}

export default Address